import React from 'react';
import Header from './Header';
import logo from '../assets/logo.png';

const About = () => {
  return (
    <div className="bg-gray-100">
      <Header />
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex justify-center mb-6">
          <img src={logo} alt="Timar Global Resources Logo" className="h-20" />
        </div>
        <h1 className="text-4xl font-bold mb-8 text-center text-blue-600">About Timar Global Resources.</h1>

        <p className="text-lg mb-6 text-gray-700 leading-relaxed">
          Timar Global Resources. is a premier provider of top-tier medical equipment and healthcare solutions. Our commitment is to empower healthcare institutions worldwide by delivering reliable and advanced medical tools that improve patient care standards and streamline healthcare processes. From diagnostic tools to surgical instruments, we serve the global healthcare community with integrity and innovation.
        </p>

        <h2 className="text-3xl font-semibold mb-4 text-blue-600">Our Vision</h2>
        <p className="text-lg mb-6 text-gray-700 leading-relaxed">
          Our vision at Timar Global Resources. is to uplift global healthcare by providing access to cutting-edge medical equipment that enhances quality of life for individuals and supports the operational needs of healthcare providers. We believe that by offering superior products and exceptional customer service, we can contribute to a healthier and more sustainable future.
        </p>

        <h2 className="text-3xl font-semibold mb-4 text-blue-600">Our Mission</h2>
        <p className="text-lg mb-6 text-gray-700 leading-relaxed">
          We are committed to delivering precisely tailored healthcare equipment that addresses the specific needs of healthcare professionals and their patients. Every product in our catalog undergoes stringent quality control to ensure reliability, safety, and efficiency in real-world healthcare settings. From operating rooms to outpatient clinics, Timar Global Resources Ltd. is a trusted partner for those dedicated to improving patient outcomes.
        </p>

        <h2 className="text-3xl font-semibold mb-4 text-blue-600">Our Core Values</h2>
        <ul className="list-disc list-inside mb-6 text-gray-700">
          <li><strong>Quality:</strong> Excellence in every product and service, ensuring longevity and effectiveness.</li>
          <li><strong>Integrity:</strong> Transparent operations and ethical practices in all transactions.</li>
          <li><strong>Innovation:</strong> A continuous search for the latest advancements in medical technology.</li>
          <li><strong>Customer Centricity:</strong> Focused on delivering solutions that meet the evolving needs of our customers worldwide.</li>
        </ul>

        <h2 className="text-3xl font-semibold mb-4 text-blue-600">Global Reach & Expertise</h2>
        <p className="text-lg mb-6 text-gray-700 leading-relaxed">
          With a robust supply network and expertise across international markets, Timar Global Resources Ltd. serves healthcare providers on a global scale. We understand the unique challenges of various healthcare environments and strive to provide equipment and supplies that meet diverse requirements. Our team of experts includes healthcare professionals, engineers, and supply chain specialists dedicated to supporting the health sector globally.
        </p>

        <h2 className="text-3xl font-semibold mb-4 text-blue-600">Our Product Range</h2>
        <p className="text-lg mb-6 text-gray-700 leading-relaxed">
          We offer an extensive array of medical equipment to cater to a wide spectrum of healthcare needs:
        </p>
        <ul className="list-disc list-inside mb-6 text-gray-700">
          <li>Diagnostic Equipment</li>
          <li>Surgical Instruments</li>
          <li>Patient Monitoring Systems</li>
          <li>Rehabilitation and Physical Therapy Tools</li>
          <li>Personal Protective Equipment (PPE)</li>
        </ul>

        <h2 className="text-3xl font-semibold mb-4 text-blue-600">Commitment to Service Excellence</h2>
        <p className="text-lg mb-6 text-gray-700 leading-relaxed">
          At Timar Global Resources., service excellence is at the heart of our operations. Our dedicated customer support team works closely with clients to provide customized solutions and ongoing support. From initial consultation to post-purchase service, we ensure a seamless experience designed to exceed expectations.
        </p>

        <h2 className="text-3xl font-semibold mb-4 text-blue-600">Contact Us</h2>
        <p className="text-lg mb-6 text-gray-700 leading-relaxed">
          For inquiries about our products or to learn more about how we can support your healthcare facility, please <a href="/contact" className="text-blue-500 underline">contact us</a>. We look forward to partnering with you in advancing healthcare standards worldwide.
        </p>
      </div>
    </div>
  );
}

export default About;

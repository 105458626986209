// // import React, { useState, useEffect } from 'react';
// // import AdminNavBar from './AdminNav';
// // import { useNavigate } from 'react-router-dom';
// // import { API_BASE_URL } from '../config';

// // const AdminOrders = () => {
// //   const [orders, setOrders] = useState([]);
// //   const [status, setStatus] = useState('');
// //   const [expandedOrder, setExpandedOrder] = useState(null); // State to track expanded orders
// //   const navigate = useNavigate();

// //   useEffect(() => {
// //     fetchOrders();
// //   }, []);

// //   const fetchOrders = async () => {
// //     const token = localStorage.getItem('token');
// //     if (!token) {
// //       navigate('/');
// //       setStatus('Authorization token not found. Please log in again.');
// //       return;
// //     }

// //     try {
// //       const response = await fetch(`${API_BASE_URL}/orders`, {
// //         headers: {
// //           'Authorization': `Bearer ${token}`,
// //           'Content-Type': 'application/json',
// //         },
// //       });

// //       if (response.ok) {
// //         const data = await response.json();
// //         setOrders(data.orders);
// //       } else {
// //         const errorData = await response.json();
// //         setStatus(errorData.message || 'Failed to fetch orders.');
// //       }
// //     } catch (error) {
// //       console.error('Error fetching orders:', error);
// //       setStatus('An error occurred. Please try again later.');
// //     }
// //   };

// //   const handleMarkAsCompleted = async (id) => {
// //     try {
// //       const response = await fetch(`${API_BASE_URL}/orders/${id}`, {
// //         method: 'PUT',
// //         headers: {
// //           'Authorization': `Bearer ${localStorage.getItem('token')}`,
// //           'Content-Type': 'application/json',
// //         },
// //       });

// //       if (response.ok) {
// //         setOrders(orders.map(order => (order.id === id ? { ...order, isCompleted: true } : order)));
// //         setStatus('Order marked as completed.');
// //       } else {
// //         const errorData = await response.json();
// //         setStatus(errorData.message || 'Failed to mark order as completed.');
// //       }
// //     } catch (error) {
// //       console.error('Error marking order:', error);
// //       setStatus('An error occurred while updating the order.');
// //     }
// //   };

// //   const handleDeleteOrder = async (id) => {
// //     try {
// //       const response = await fetch(`${API_BASE_URL}/orders/${id}`, {
// //         method: 'DELETE',
// //         headers: {
// //           'Authorization': `Bearer ${localStorage.getItem('token')}`,
// //           'Content-Type': 'application/json',
// //         },
// //       });

// //       if (response.ok) {
// //         setOrders(orders.filter(order => order.id !== id));
// //         setStatus('Order deleted.');
// //       } else {
// //         const errorData = await response.json();
// //         setStatus(errorData.message || 'Failed to delete order.');
// //       }
// //     } catch (error) {
// //       console.error('Error deleting order:', error);
// //       setStatus('An error occurred while deleting the order.');
// //     }
// //   };

// //   const handleToggleItems = (orderId) => {
// //     setExpandedOrder(expandedOrder === orderId ? null : orderId);
// //   };

// //   return (
// //     <div className="bg-gray-50 min-h-screen">
// //       <AdminNavBar />
// //       <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12">
// //         <h1 className="text-3xl md:text-4xl font-extrabold text-center text-blue-700 mb-6 md:mb-8">
// //           Admin Orders
// //         </h1>

// //         {status && <p className="text-green-600 text-center mb-4">{status}</p>}

// //         <div className="grid grid-cols-1 gap-6">
// //           <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg overflow-hidden">
// //             <h2 className="text-xl md:text-2xl font-semibold text-blue-600 mb-4 md:mb-6">Orders List</h2>

// //             {orders.length > 0 ? (
// //               <div className="overflow-x-auto">
// //                 <table className="min-w-full border-collapse table-auto">
// //                   <thead>
// //                     <tr>
// //                       <th className="px-4 py-2 text-left text-sm md:text-base font-medium text-gray-700">Customer Name</th>
// //                       <th className="px-4 py-2 text-left text-sm md:text-base font-medium text-gray-700">Total Amount</th>
// //                       <th className="px-4 py-2 text-left text-sm md:text-base font-medium text-gray-700">Status</th>
// //                       <th className="px-4 py-2 text-left text-sm md:text-base font-medium text-gray-700">Actions</th>
// //                     </tr>
// //                   </thead>
// //                   <tbody>
// //                     {orders.map(order => (
// //                       <tr key={order.id} className="hover:bg-gray-100 transition duration-200">
// //                         <td className="px-4 py-2">{order.customer_name}</td>
// //                         <td className="px-4 py-2">₦{order.total_amount.toFixed(2)}</td>
// //                         <td className="px-4 py-2">{order.isCompleted ? 'Completed' : 'Pending'}</td>
// //                         <td className="px-4 py-2 space-y-2 sm:space-y-0 sm:space-x-2 flex flex-col sm:flex-row">
// //                           {!order.isCompleted && (
// //                             <button
// //                               onClick={() => handleMarkAsCompleted(order.id)}
// //                               className="bg-green-600 text-white text-xs md:text-sm px-3 py-2 rounded-md hover:bg-green-700 transition"
// //                               title="Mark as Completed"
// //                             >
// //                               Mark as Completed
// //                             </button>
// //                           )}
// //                           <button
// //                             onClick={() => handleDeleteOrder(order.id)}
// //                             className="bg-red-600 text-white text-xs md:text-sm px-3 py-2 rounded-md hover:bg-red-700 transition"
// //                             title="Delete Order"
// //                           >
// //                             Delete
// //                           </button>
// //                           <button
// //                             onClick={() => handleToggleItems(order.id)}
// //                             className="bg-blue-600 text-white text-xs md:text-sm px-3 py-2 rounded-md hover:bg-blue-700 transition"
// //                             title="Toggle Order Items"
// //                           >
// //                             {expandedOrder === order.id ? 'Hide Items' : 'View Items'}
// //                           </button>
// //                         </td>
// //                       </tr>
// //                     ))}
// //                   </tbody>
// //                 </table>
// //               </div>
// //             ) : (
// //               <p className="text-center text-gray-600">No orders available.</p>
// //             )}

// //             {expandedOrder && (
// //               <div className="mt-4 bg-gray-100 p-4 rounded-lg">
// //                 <h3 className="text-lg md:text-xl font-semibold text-blue-600 mb-4">Items:</h3>
// //                 <ul className="list-disc pl-5">
// //                   {orders
// //                     .find(order => order.id === expandedOrder)
// //                     .items.map(item => (
// //                       <li key={item.id} className="text-sm md:text-base">
// //                         <span className="font-medium">{item.name}</span> - Quantity: {item.quantity} - Price: ${item.price.toFixed(2)}
// //                       </li>
// //                     ))}
// //                 </ul>
// //               </div>
// //             )}
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default AdminOrders;



// import React, { useState, useEffect } from 'react';
// import AdminNavBar from './AdminNav';
// import { useNavigate } from 'react-router-dom';
// import { API_BASE_URL } from '../config';
// import OrderItems from './OrderItems'; // Import the new component

// const AdminOrders = () => {
//   const [orders, setOrders] = useState([]);
//   const [status, setStatus] = useState('');
//   const [expandedOrder, setExpandedOrder] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     fetchOrders();
//   }, []);

//   const fetchOrders = async () => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       navigate('/');
//       setStatus('Authorization token not found. Please log in again.');
//       return;
//     }

//     try {
//       const response = await fetch(`${API_BASE_URL}/orders`, {
//         headers: {
//           'Authorization': `Bearer ${token}`,
//           'Content-Type': 'application/json',
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setOrders(data.orders);
//       } else {
//         const errorData = await response.json();
//         setStatus(errorData.message || 'Failed to fetch orders.');
//       }
//     } catch (error) {
//       console.error('Error fetching orders:', error);
//       setStatus('An error occurred. Please try again later.');
//     }
//   };

//   const handleMarkAsCompleted = async (id) => {
//     try {
//       const response = await fetch(`${API_BASE_URL}/orders/${id}`, {
//         method: 'PUT',
//         headers: {
//           'Authorization': `Bearer ${localStorage.getItem('token')}`,
//           'Content-Type': 'application/json',
//         },
//       });

//       if (response.ok) {
//         setOrders(orders.map(order => (order.id === id ? { ...order, isCompleted: true } : order)));
//         setStatus('Order marked as completed.');
//       } else {
//         const errorData = await response.json();
//         setStatus(errorData.message || 'Failed to mark order as completed.');
//       }
//     } catch (error) {
//       console.error('Error marking order:', error);
//       setStatus('An error occurred while updating the order.');
//     }
//   };

//   const handleDeleteOrder = async (id) => {
//     try {
//       const response = await fetch(`${API_BASE_URL}/orders/${id}`, {
//         method: 'DELETE',
//         headers: {
//           'Authorization': `Bearer ${localStorage.getItem('token')}`,
//           'Content-Type': 'application/json',
//         },
//       });

//       if (response.ok) {
//         setOrders(orders.filter(order => order.id !== id));
//         setStatus('Order deleted.');
//       } else {
//         const errorData = await response.json();
//         setStatus(errorData.message || 'Failed to delete order.');
//       }
//     } catch (error) {
//       console.error('Error deleting order:', error);
//       setStatus('An error occurred while deleting the order.');
//     }
//   };

//   const handleToggleItems = (orderId) => {
//     setExpandedOrder(expandedOrder === orderId ? null : orderId);
//   };

//   return (
//     <div className="bg-gray-50 min-h-screen">
//       <AdminNavBar />
//       <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12">
//         <h1 className="text-3xl md:text-4xl font-extrabold text-center text-blue-700 mb-6 md:mb-8">
//           Admin Orders
//         </h1>

//         {status && <p className="text-green-600 text-center mb-4">{status}</p>}

//         <div className="grid grid-cols-1 gap-6">
//           <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg overflow-hidden">
//             <h2 className="text-xl md:text-2xl font-semibold text-blue-600 mb-4 md:mb-6">Orders List</h2>

//             {orders.length > 0 ? (
//               <div className="overflow-x-auto">
//                 <table className="min-w-full border-collapse table-auto">
//                   <thead>
//                     <tr>
//                       <th className="px-4 py-2 text-left text-sm md:text-base font-medium text-gray-700">Customer Name</th>
//                       <th className="px-4 py-2 text-left text-sm md:text-base font-medium text-gray-700">Total Amount</th>
//                       <th className="px-4 py-2 text-left text-sm md:text-base font-medium text-gray-700">Status</th>
//                       <th className="px-4 py-2 text-left text-sm md:text-base font-medium text-gray-700">Actions</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {orders.map(order => (
//                       <tr key={order.id} className="hover:bg-gray-100 transition duration-200">
//                         <td className="px-4 py-2">{order.customer_name}</td>
//                         <td className="px-4 py-2">₦{order.total_amount.toFixed(2)}</td>
//                         <td className="px-4 py-2">{order.isCompleted ? 'Completed' : 'Pending'}</td>
//                         <td className="px-4 py-2 space-y-2 sm:space-y-0 sm:space-x-2 flex flex-col sm:flex-row">
//                           {!order.isCompleted && (
//                             <button
//                               onClick={() => handleMarkAsCompleted(order.id)}
//                               className="bg-green-600 text-white text-xs md:text-sm px-3 py-2 rounded-md hover:bg-green-700 transition"
//                               title="Mark as Completed"
//                             >
//                               Mark as Completed
//                             </button>
//                           )}
//                           <button
//                             onClick={() => handleDeleteOrder(order.id)}
//                             className="bg-red-600 text-white text-xs md:text-sm px-3 py-2 rounded-md hover:bg-red-700 transition"
//                             title="Delete Order"
//                           >
//                             Delete
//                           </button>
//                           <button
//                             onClick={() => handleToggleItems(order.id)}
//                             className="bg-blue-600 text-white text-xs md:text-sm px-3 py-2 rounded-md hover:bg-blue-700 transition"
//                             title="Toggle Order Items"
//                           >
//                             {expandedOrder === order.id ? 'Hide Items' : 'View Items'}
//                           </button>
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </div>
//             ) : (
//               <p className="text-center text-gray-600">No orders available.</p>
//             )}

//             {expandedOrder && (
//               <OrderItems items={orders.find(order => order.id === expandedOrder).items} />
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AdminOrders;



// AdminOrders.js
import React, { useState, useEffect } from 'react';
import AdminNavBar from './AdminNav';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';
import OrderItems from './OrderItems';

const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState('');
  const [expandedOrderItems, setExpandedOrderItems] = useState(null); // Track items for the modal
  const navigate = useNavigate();

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/');
      setStatus('Authorization token not found. Please log in again.');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/orders`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setOrders(data.orders);
      } else {
        const errorData = await response.json();
        setStatus(errorData.message || 'Failed to fetch orders.');
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
      setStatus('An error occurred. Please try again later.');
    }
  };

  const handleMarkAsCompleted = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/orders/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setOrders(orders.map(order => (order.id === id ? { ...order, isCompleted: true } : order)));
        setStatus('Order marked as completed.');
      } else {
        const errorData = await response.json();
        setStatus(errorData.message || 'Failed to mark order as completed.');
      }
    } catch (error) {
      console.error('Error marking order:', error);
      setStatus('An error occurred while updating the order.');
    }
  };

  const handleDeleteOrder = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/orders/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setOrders(orders.filter(order => order.id !== id));
        setStatus('Order deleted.');
      } else {
        const errorData = await response.json();
        setStatus(errorData.message || 'Failed to delete order.');
      }
    } catch (error) {
      console.error('Error deleting order:', error);
      setStatus('An error occurred while deleting the order.');
    }
  };

  const handleViewItems = (orderId) => {
    const selectedOrder = orders.find(order => order.id === orderId);
    setExpandedOrderItems(selectedOrder.items); // Set items to display in modal
  };

  const handleCloseModal = () => {
    setExpandedOrderItems(null); // Close modal by clearing items
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      <AdminNavBar />
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12">
        <h1 className="text-3xl md:text-4xl font-extrabold text-center text-blue-700 mb-6 md:mb-8">
          Admin Orders
        </h1>

        {status && <p className="text-green-600 text-center mb-4">{status}</p>}

        <div className="grid grid-cols-1 gap-6">
          <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg overflow-hidden">
            <h2 className="text-xl md:text-2xl font-semibold text-blue-600 mb-4 md:mb-6">Orders List</h2>

            {orders.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full border-collapse table-auto">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 text-left text-sm md:text-base font-medium text-gray-700">Customer Name</th>
                      <th className="px-4 py-2 text-left text-sm md:text-base font-medium text-gray-700">Total Amount</th>
                      <th className="px-4 py-2 text-left text-sm md:text-base font-medium text-gray-700">Status</th>
                      <th className="px-4 py-2 text-left text-sm md:text-base font-medium text-gray-700">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map(order => (
                      <tr key={order.id} className="hover:bg-gray-100 transition duration-200">
                        <td className="px-4 py-2">{order.customer_name}</td>
                        <td className="px-4 py-2">₦{order.total_amount.toFixed(2)}</td>
                        <td className="px-4 py-2">{order.isCompleted ? 'Completed' : 'Pending'}</td>
                        <td className="px-4 py-2 space-y-2 sm:space-y-0 sm:space-x-2 flex flex-col sm:flex-row">
                          {!order.isCompleted && (
                            <button
                              onClick={() => handleMarkAsCompleted(order.id)}
                              className="bg-green-600 text-white text-xs md:text-sm px-3 py-2 rounded-md hover:bg-green-700 transition"
                              title="Mark as Completed"
                            >
                              Mark as Completed
                            </button>
                          )}
                          <button
                            onClick={() => handleDeleteOrder(order.id)}
                            className="bg-red-600 text-white text-xs md:text-sm px-3 py-2 rounded-md hover:bg-red-700 transition"
                            title="Delete Order"
                          >
                            Delete
                          </button>
                          <button
                            onClick={() => handleViewItems(order.id)}
                            className="bg-blue-600 text-white text-xs md:text-sm px-3 py-2 rounded-md hover:bg-blue-700 transition"
                            title="View Order Items"
                          >
                            View Items
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="text-center text-gray-600">No orders available.</p>
            )}
          </div>
        </div>

        {expandedOrderItems && (
          <OrderItems items={expandedOrderItems} onClose={handleCloseModal} />
        )}
      </div>
    </div>
  );
};

export default AdminOrders;

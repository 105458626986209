import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../config';

const Cart = () => {
  const [cart, setCart] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(1); // Default exchange rate is 1 (USD to USD)
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/exchange-rate`);
        setExchangeRate(response.data.usd_ngn); // Set the USD to NGN exchange rate
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
      }
    };

    fetchExchangeRate();

    const savedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(savedCart);
    updatePrices(savedCart);
  }, []);

  const updatePrices = (updatedCart) => {
    const total = updatedCart.reduce((sum, item) => sum + item.price * item.quantity, 0);
    setTotalPrice(total);
    setDiscountedPrice(total * 0.95); // Apply 5% discount
  };

  const handleQuantityChange = (productId, quantity) => {
    const updatedCart = cart.map(item =>
      item.id === productId ? { ...item, quantity } : item
    );
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    updatePrices(updatedCart);
  };

  const removeFromCart = (productId) => {
    const updatedCart = cart.filter(item => item.id !== productId);
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    updatePrices(updatedCart);
  };

  const proceedToCheckout = () => {
    navigate('/checkout');
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-8 text-center">Your Cart</h2>

      <div className="grid gap-6">
        {cart.map((product) => (
          <div key={product.id} className="flex flex-col sm:flex-row justify-between items-center p-4 bg-white rounded-lg shadow-md">
            <Link to={`/products/${product.id}`} className="flex-1">
              <h3 className="font-bold text-lg">{product.name}</h3>
              <p className="text-gray-600">Price: ₦{(product.price * exchangeRate).toFixed(2)}</p>
            </Link>
            <input
              type="number"
              min="1"
              value={product.quantity}
              onChange={(e) => handleQuantityChange(product.id, Number(e.target.value))}
              className="border w-16 p-2 text-center mt-4 sm:mt-0"
            />
            <button onClick={() => removeFromCart(product.id)} className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded mt-4 sm:mt-0">
              Delete
            </button>
          </div>
        ))}
      </div>

      <div className="mt-8 text-right">
        <h3 className="text-lg md:text-xl font-semibold">Total Price: ₦{(totalPrice * exchangeRate).toFixed(2)}</h3>
        <h3 className="text-lg md:text-xl font-semibold text-green-600">
          Discounted Price (5% Off): ₦{(discountedPrice * exchangeRate).toFixed(2)}
        </h3>
        <button onClick={proceedToCheckout} className="bg-green-600 hover:bg-green-700 text-white py-3 px-6 rounded mt-6">
          Proceed to Checkout
        </button>
      </div>

      <div className="text-center mt-8">
        <Link to="/" className="inline-block bg-blue-600 hover:bg-blue-700 text-white py-3 px-6 rounded-lg shadow-lg transition duration-300">
          Continue Shopping
        </Link>
      </div>
    </div>
  );
};

export default Cart;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FiShoppingCart } from 'react-icons/fi'; // Import cart icon
import Header from './Header';
import Banner from './Banner';
import Footer from './Footer';
import { useUser } from '../UserContext';
import { API_BASE_URL } from '../config';


const Home = () => {
  const { user, rememberUser } = useUser();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [quantities, setQuantities] = useState({});
  const [exchangeRate, setExchangeRate] = useState(null);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${ API_BASE_URL }/products`);
      setProducts(response.data);
      setFilteredProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchExchangeRate = async () => {
    try {
      const response = await axios.get(`${ API_BASE_URL }/api/exchange-rate`);
      setExchangeRate(response.data.usd_ngn);
    } catch (error) {
      console.error('Error fetching exchange rate:', error);
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchExchangeRate();
    const savedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(savedCart);
  }, []);

  const addToCart = (productId) => {
    const productQuantity = quantities[productId] || 1;
    const product = products.find(item => item.id === productId);
    const existingItem = cart.find(item => item.id === productId);
    let updatedCart;

    if (existingItem) {
      updatedCart = cart.map(item =>
        item.id === productId ? { ...item, quantity: item.quantity + productQuantity } : item
      );
    } else {
      updatedCart = [...cart, { ...product, quantity: productQuantity }];
    }

    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    alert("Successfully added to cart!");
  };

  const handleQuantityChange = (productId, value) => {
    setQuantities(prevQuantities => ({
      ...prevQuantities,
      [productId]: value > 0 ? value : 1
    }));
  };

  const handleCheckout = () => {
    window.location.href = '/cart';
  };

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = products.filter((product) =>
      (product.name && product.name.toLowerCase().includes(lowerCaseQuery)) ||
      (product.shortDescription && product.shortDescription.toLowerCase().includes(lowerCaseQuery))
    );
    setFilteredProducts(filtered);
  }, [searchQuery, products]);

  return (
    <div>
      <Header />
      <Banner />

      {/* Cart Icon with Badge */}
      <div className="fixed top-16 right-4 z-50 flex items-center">
        <Link to="/cart" className="relative flex items-center">
          <FiShoppingCart size={30} className="text-blue-800" />
          {cart.length > 0 && (
            <span className="absolute top-0 right-0 inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-600 rounded-full">
              {cart.reduce((total, item) => total + item.quantity, 0)}
            </span>
          )}
        </Link>
      </div>

      <div className="container mx-auto px-4 sm:px-6 lg:px-8 mt-10">
        <h1 className="text-3xl font-semibold mb-6 text-center">Welcome to Our Store</h1>
        <p className="text-lg text-gray-600 text-center mb-8">Discover our range of products below!</p>
        
        <div className="text-center mb-6 p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
          <p className="font-semibold">🎉 Enjoy a 5% discount on all purchases! 🎉</p>
        </div>

        <div className="mb-6 flex justify-center">
          <input
            type="text"
            placeholder="Search products..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full max-w-md px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {filteredProducts.map((product) => (
            <div key={product.id} className="bg-white shadow-md rounded-lg overflow-hidden">
              <Link to={`/products/${product.id}`} className="block">
                <img
                  src={`${ API_BASE_URL }/media/${product.image}`}
                  alt={product.name}
                  className="w-full h-48 object-cover"
                />
                <div className="p-4">
                  <h3 className="text-lg font-bold">{product.name}</h3>
                  <p className="text-gray-600 mt-1">
                    {exchangeRate ? (
                      <span>₦{(product.price * exchangeRate).toFixed(2)}</span>
                    ) : (
                      <span>${product.price}</span>
                    )}
                  </p>
                  <p className="text-gray-700 mt-2">{product.short_description}</p>
                </div>
              </Link>
              <div className="flex items-center justify-between p-4">
                <input
                  type="number"
                  min="1"
                  value={quantities[product.id] || 1}
                  onChange={(e) => handleQuantityChange(product.id, Number(e.target.value))}
                  className="border rounded-lg w-16 text-center"
                />
                <button
                  onClick={() => addToCart(product.id)}
                  className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg transition duration-200"
                >
                  Add to Cart
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-6 text-center">
          <button
            onClick={handleCheckout}
            className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded text-xl font-semibold transition duration-200"
          >
            Checkout
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;

// OrderItems.jsx
import React from 'react';

const OrderItems = ({ items, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-xl font-semibold text-blue-600 mb-4">Order Items</h2>
        <ul className="list-disc pl-5">
          {items.map(item => (
            <li key={item.id} className="text-sm md:text-base">
              <span className="font-medium">{item.name}</span> - Quantity: {item.quantity} - Price: ${item.price.toFixed(2)}
            </li>
          ))}
        </ul>
        <button
          onClick={onClose}
          className="mt-4 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default OrderItems;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminNavBar from './AdminNav';
import { API_BASE_URL } from '../config';

const AdminSubmissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/');
    } else {
      fetchSubmissions(token);
    }
  }, [navigate]);

  const fetchSubmissions = async (token) => {
    try {
      const response = await fetch(`${API_BASE_URL}/contact/submissions`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      if (response.ok) {
        const data = await response.json();
        setSubmissions(data.submissions || []);
      } else if (response.status === 401) {
        // Token expired or invalid
        localStorage.removeItem('token');
        navigate('/'); // Redirect to login
      } else {
        console.error('Error fetching submissions:', response.status);
      }
    } catch (error) {
      console.error('Error fetching submissions:', error);
    }
  };

  const markAsRead = async (id) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${API_BASE_URL}/contact/submissions/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ isRead: true }),
      });

      if (response.ok) {
        const data = await response.json();
        setSubmissions((prevSubmissions) =>
          prevSubmissions.map((submission) =>
            submission.id === id ? { ...submission, isRead: true, read_at: data.read_at } : submission
          )
        );
      } else if (response.status === 401) {
        localStorage.removeItem('token');
        navigate('/'); // Redirect to login
      } else {
        console.error('Failed to mark as read:', response.status);
      }
    } catch (error) {
      console.error('Error marking submission as read:', error);
    }
  };

  const deleteSubmission = async (id) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${API_BASE_URL}/contact/submissions/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        setSubmissions((prevSubmissions) => prevSubmissions.filter((submission) => submission.id !== id));
      } else if (response.status === 401) {
        localStorage.removeItem('token');
        navigate('/'); // Redirect to login
      } else {
        console.error('Failed to delete submission:', response.status);
      }
    } catch (error) {
      console.error('Error deleting submission:', error);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <AdminNavBar />
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-4xl font-bold text-center text-blue-600 mb-8">Contact Forms</h1>
        <div className="bg-white p-6 rounded-lg shadow-md">
          {submissions.length > 0 ? (
            <ul className="space-y-4">
              {submissions.map((submission) => (
                <li
                  key={submission.id}
                  className={`flex flex-col md:flex-row justify-between items-start md:items-center p-4 border rounded-lg ${submission.isRead ? 'bg-gray-200' : 'bg-white'}`}
                >
                  <div className="flex-1">
                    <h3 className="font-bold">{submission.name}</h3>
                    <p className="text-sm text-gray-600">{submission.email}</p>
                    <p className="font-medium">{submission.subject}</p>
                    <p className="mt-1">{submission.message}</p>
                    {/* Display phone number and WhatsApp number */}
                    {submission.phonenumber && (
                      <p className="mt-1"><strong>Phone:</strong> {submission.phonenumber}</p>
                    )}
                    {submission.whatsapp_number && (
                      <p className="mt-1"><strong>WhatsApp:</strong> {submission.whatsapp_number}</p>
                    )}
                    {submission.isRead && submission.read_at && (
                      <p className="text-xs text-gray-500 mt-1">Marked as read on: {new Date(submission.read_at).toLocaleString()}</p>
                    )}
                  </div>
                  <button
                    onClick={() => markAsRead(submission.id)}
                    className={`mt-2 md:mt-0 md:ml-4 text-white px-3 py-1 rounded-lg ${submission.isRead ? 'bg-gray-400' : 'bg-blue-600'}`}
                    disabled={submission.isRead}
                  >
                    {submission.isRead ? 'Read' : 'Mark as Read'}
                  </button>
                  <button
                    onClick={() => deleteSubmission(submission.id)}
                    className="mt-2 md:mt-0 md:ml-4 text-white bg-red-600 px-3 py-1 rounded-lg"
                  >
                    Delete
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p>No submissions found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminSubmissions;

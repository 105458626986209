import React, { useState } from 'react';
import Header from './Header';
import { FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { API_BASE_URL } from '../config';
const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    phonenumber: '', // New field for phone number
    whatsapp_number: '' // New field for WhatsApp number
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_BASE_URL}/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setStatus('Message sent successfully!');
        setFormData({ name: '', email: '', subject: '', message: '', phonenumber: '', whatsapp_number: '' }); // Reset all fields
      } else {
        setStatus('Failed to send message. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setStatus('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header />
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-4xl font-bold text-center text-blue-600 mb-8">Contact Us</h1>
        <p className="text-lg text-gray-700 text-center mb-10">
          Have questions or need more information? Reach out to us, and our team will be happy to assist you.
        </p>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Contact Form */}
          <form className="bg-white p-8 rounded-lg shadow-md" onSubmit={handleSubmit}>
            <h2 className="text-2xl font-semibold text-blue-600 mb-6">Send Us a Message</h2>

            {status && <p className="text-green-600 text-center mb-4">{status}</p>}

            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
              />
            </div>

            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
              />
            </div>

            <div className="mb-4">
              <label htmlFor="subject" className="block text-gray-700">Subject</label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
              />
            </div>

            <div className="mb-4">
              <label htmlFor="phonenumber" className="block text-gray-700">Phone Number</label>
              <input
                type="text"
                id="phonenumber"
                name="phonenumber"
                value={formData.phonenumber}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
              />
            </div>

            <div className="mb-4">
              <label htmlFor="whatsapp_number" className="block text-gray-700">WhatsApp Number</label>
              <input
                type="text"
                id="whatsapp_number"
                name="whatsapp_number"
                value={formData.whatsapp_number}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
              />
            </div>

            <div className="mb-6">
              <label htmlFor="message" className="block text-gray-700">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows="5"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
              ></textarea>
            </div>

            <button type="submit" className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg w-full transition duration-200">
              Submit
            </button>
          </form>

          {/* Contact Information */}
          <div className="bg-white p-8 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold text-blue-600 mb-6">Get in Touch</h2>
            <p className="text-lg text-gray-700 mb-4">
              Timar Global Resources is here to answer any questions you may have about our products or services. Feel free to reach out to us via phone, email, or the form.
            </p>
            <p className="text-lg text-gray-700 mb-4">
              <strong>Phone:</strong> +234 901 988 9668
            </p>
            <p className="text-lg text-gray-700 mb-4">
              <strong>Email:</strong> <a href="mailto:timarglobalresources@gmail.com" className="text-blue-500 underline">timarglobalresources@gmail.com</a>
            </p>
            <p className="text-lg text-gray-700">
              <strong>Business Hours:</strong> Mon - Sat: 7 AM - 5 PM GMT.
            </p>

            {/* Social Media Links */}
            <div className="flex space-x-4 mt-6">
              <a href="https://www.instagram.com/timarworld" target="_blank" rel="noopener noreferrer" aria-label="Instagram" className="text-pink-600 text-3xl">
                <FaInstagram />
              </a>
              <a href="https://wa.me/2349019889668" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp" className="text-green-600 text-3xl">
                <FaWhatsapp />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminNavBar from './AdminNav';
import { API_BASE_URL } from '../config';

const AdminSettings = () => {
  const [admins, setAdmins] = useState([]); // Initialize as an empty array
  const [newAdmin, setNewAdmin] = useState({ username: '', email: '', phonenumber: '', password: '' });
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/');
    } else {
      fetchUsers(token);
    }
  }, [navigate]);

   const fetchUsers = async (token) => {
    try {
      const response = await fetch(`${API_BASE_URL}/users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      if (response.status === 401) {
        // Token is invalid or expired; redirect to login
        console.error("Unauthorized: Token may be invalid or expired");
        localStorage.removeItem('token'); // Clear any invalid token
        navigate('/'); // Redirect to login
        return;
      }
      
      const data = await response.json();
      
      // Ensure that the data is an array before setting it to admins
      setAdmins(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching users:', error);
      setAdmins([]); // Set as an empty array on error
    }
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAdmin((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddAdmin = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${API_BASE_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ...newAdmin, role: 'admin' }),
      });
      if (response.ok) {
        fetchUsers(token);
        setNewAdmin({ username: '', email: '', phonenumber: '', password: '' });
      } else {
        console.error('Failed to add admin');
      }
    } catch (error) {
      console.error('Error adding admin:', error);
    }
  };

  const handleRemoveAdmin = async (id) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${API_BASE_URL}/users/${id}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.ok) {
        setAdmins(admins.filter((admin) => admin.id !== id));
      }
    } catch (error) {
      console.error('Error deleting admin:', error);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <AdminNavBar />
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-4xl font-bold text-center text-blue-600 mb-8">Admin Settings</h1>

        <section className="mb-10">
          <h2 className="text-3xl font-semibold text-blue-600 mb-4">Admin Management</h2>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold mb-4">Add New Admin</h3>
            <form onSubmit={handleAddAdmin} className="flex flex-col gap-4 mb-4">
              <input
                type="text"
                name="username"
                placeholder="Username"
                value={newAdmin.username}
                onChange={handleInputChange}
                className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={newAdmin.email}
                onChange={handleInputChange}
                className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                required
              />
              <input
                type="text"
                name="phonenumber"
                placeholder="Phone Number"
                value={newAdmin.phonenumber}
                onChange={handleInputChange}
                className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                required
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={newAdmin.password}
                onChange={handleInputChange}
                className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                required
              />
              <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded-lg">
                Add Admin
              </button>
            </form>

            {/* Admin List */}
            {Array.isArray(admins) && admins.length > 0 ? (
              <ul>
                {admins.map((admin) => (
                  <li key={admin.id} className="flex justify-between items-center mb-2">
                    <span>{admin.username} - {admin.email}</span>
                    <button
                      onClick={() => handleRemoveAdmin(admin.id)}
                      className="text-red-500 underline"
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No admins found.</p>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default AdminSettings;

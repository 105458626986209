import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import './styles.css';

const Header = () => {
  return (
    <Navbar expand="lg" className="navbar-custom py-3 shadow-sm">
      <Navbar.Brand href="/" className="navbar-brand-custom font-weight-bold text-primary">
        Timar World
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbar-content" />
      <Navbar.Collapse id="navbar-content" className="justify-content-between">
        <Nav className="ml-auto">
          <Nav.Link href="/" className="nav-link-custom">Home</Nav.Link>
          <Nav.Link href="/cart" className="nav-link-custom">Cart</Nav.Link>
          <NavDropdown title="Categories" id="navbar-dropdown" className="nav-link-custom">
            <NavDropdown.Item href="/catalogue">Catalogue</NavDropdown.Item>
            <NavDropdown.Item href="/about">About Us</NavDropdown.Item>
            <NavDropdown.Item href="/contact">Contact</NavDropdown.Item>
            <NavDropdown.Item href="/admin/login">Admin</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;

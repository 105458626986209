import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProcessingOrder = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate order processing with a delay, then redirect to success page
    const timer = setTimeout(() => {
      navigate('/success');
    }, 3000); // Adjust timing as needed (3 seconds here)

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="container mx-auto px-4 py-8 bg-white shadow-lg rounded-lg text-center">
      <h2 className="text-4xl font-bold mb-6 text-gray-800">Processing Your Order</h2>
      <p className="text-gray-600">Please wait while we confirm your order details...</p>
    </div>
  );
};

export default ProcessingOrder;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AdminNavBar from './AdminNav';
import { API_BASE_URL } from '../config';

const AdminHome = () => {
  const [products, setProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({
    name: '',
    price: '',
    shortDescription: '',
    description: '',
    image: null,
    video: null,
    about: ''
  });
  const [editingProduct, setEditingProduct] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  // Check for authentication token
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/'); // Redirect to login if token is not found
    }
  }, [navigate]);

  // Fetch products from the Flask API
  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/products`);
      setProducts(response.data);
      setFilteredProducts(response.data); // Initialize filteredProducts with all products
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  // Handle form input changes for text fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct({ ...newProduct, [name]: value });
  };

  // Handle file input changes for image and video uploads
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setNewProduct({ ...newProduct, [name]: files[0] });
  };

  // Add a new product
  const handleAddProduct = async () => {
    const formData = new FormData();
    Object.entries(newProduct).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const token = localStorage.getItem('token');  // Retrieve token from local storage

    try {
      await axios.post(`${API_BASE_URL}/products`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,  // Include JWT token
        },
      });
      fetchProducts();
      resetForm();
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  // Reset the form fields after adding/updating
  const resetForm = () => {
    setEditingProduct(null);
    setNewProduct({
      name: '',
      price: '',
      shortDescription: '',
      description: '',
      image: null,
      video: null,
      about: ''
    });
  };

  // Delete a product
  const handleDeleteProduct = async (id) => {
    const token = localStorage.getItem('token');

    try {
      await axios.delete(`${API_BASE_URL}/products/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,  // Include JWT token
        },
      });
      fetchProducts();
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  // Populate the form for editing a product
  const handleEditProduct = (product) => {
    setEditingProduct(product);
    setNewProduct({
      name: product.name,
      price: product.price,
      shortDescription: product.short_description || '',
      description: product.description || '',
      image: null,  // Reset the file input since you won't edit files directly
      video: null,
      about: product.about || ''
    });
  };

  // Update an existing product
  const handleUpdateProduct = async () => {
    const formData = new FormData();
    Object.entries(newProduct).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const token = localStorage.getItem('token');

    try {
      await axios.put(`${API_BASE_URL}/products/${editingProduct.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,  // Include JWT token
        },
      });
      fetchProducts();
      resetForm();
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  // Update filtered products when search query changes
  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = products.filter((product) =>
      (product.name && product.name.toLowerCase().includes(lowerCaseQuery)) ||
      (product.short_description && product.short_description.toLowerCase().includes(lowerCaseQuery))
    );
    setFilteredProducts(filtered);
  }, [searchQuery, products]);

  return (
    <div>
      <AdminNavBar />
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 mt-10">
        {/* Form for adding/editing products */}
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-6">
          <h3 className="text-3xl mb-4">{editingProduct ? 'Edit Product' : 'Add New Product'}</h3>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2 text-gray-700">Name</label>
            <input
              type="text"
              name="name"
              value={newProduct.name}
              onChange={handleInputChange}
              className="border rounded-lg p-2 w-full focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2 text-gray-700">Price</label>
            <input
              type="text"
              name="price"
              value={newProduct.price}
              onChange={handleInputChange}
              className="border rounded-lg p-2 w-full focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2 text-gray-700">Short Description</label>
            <input
              type="text"
              name="shortDescription"
              value={newProduct.shortDescription}
              onChange={handleInputChange}
              className="border rounded-lg p-2 w-full focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2 text-gray-700">Description</label>
            <textarea
              name="description"
              value={newProduct.description}
              onChange={handleInputChange}
              className="border rounded-lg p-2 w-full focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2 text-gray-700">Upload Image</label>
            <input
              type="file"
              name="image"
              onChange={handleFileChange}
              className="border rounded-lg p-2 w-full focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2 text-gray-700">Upload Video</label>
            <input
              type="file"
              name="video"
              onChange={handleFileChange}
              className="border rounded-lg p-2 w-full focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2 text-gray-700">About Product</label>
            <textarea
              name="about"
              value={newProduct.about}
              onChange={handleInputChange}
              className="border rounded-lg p-2 w-full focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <button
            onClick={editingProduct ? handleUpdateProduct : handleAddProduct}
            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg transition duration-200"
          >
            {editingProduct ? 'Update Product' : 'Add Product'}
          </button>
        </div>

        {/* Search Bar */}
        <div className="mb-6 flex justify-center">
          <input
            type="text"
            placeholder="Search products..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full max-w-md px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
        </div>
        {/* Product Cards */}
        <h3 className="text-3xl mb-4">Products</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {filteredProducts.map((product) => (
            <div key={product.id} className="border p-4 rounded-lg shadow-md">
               <Link to={`/products/${product.id}`} className="block">
                <img
                  src={`${ API_BASE_URL }/media/${product.image}`}
                  alt={product.name}
                  className="w-full h-48 object-cover"
                />
              <h4 className="text-xl font-semibold mb-2">{product.name}</h4>
              <p className="text-gray-600">${product.price}</p>
              <p className="text-gray-700">{product.short_description}</p>
              </Link>
              <div className="flex mt-4 space-x-2">
                <button
                  onClick={() => handleEditProduct(product)}
                  className="bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded-lg"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteProduct(product.id)}
                  className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-lg"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminHome;

import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { PaystackButton } from 'react-paystack';
import axios from 'axios';
import { API_BASE_URL } from '../config';


const Checkout = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || []);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // Fetch exchange rate from the Flask backend
  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/exchange-rate`); // Flask endpoint
        setExchangeRate(response.data.usd_ngn); // Set exchange rate from the API
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
        setExchangeRate(1); // Fallback to 1 if there is an error
      }
    };

    fetchExchangeRate();
  }, []);

  useEffect(() => {
    if (exchangeRate !== null) {
      const initialTotal = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);
      const initialDiscounted = initialTotal * 0.95; // Apply 5% discount
      setTotalAmount(initialTotal * exchangeRate); // Multiply by exchange rate
      setDiscountedAmount(initialDiscounted * exchangeRate); // Apply discount and multiply by exchange rate
    }
  }, [cart, exchangeRate]);

  const handlePaymentSuccess = async () => {
    alert('Payment Successful!');
    localStorage.removeItem('cart');
    
    try {
      // Send order details to Flask backend
      await axios.post(`${API_BASE_URL}/orders`, {
        customer_name: name,
        customer_email: email,
        delivery_address: address,
        items: cart,
        total_amount: totalAmount,
        discounted_amount: discountedAmount,
        payment_reference: paystackConfig.reference, // Pass payment reference from Paystack
      });
    } catch (error) {
      console.error('Error saving order:', error);
    }

    navigate('/processing');
  };

  const handlePaymentClose = () => {
    alert('Payment was not completed');
  };

  const itemsMetadata = cart.map((item) => ({
    name: item.name,
    quantity: item.quantity,
    price: item.price,
  }));

  const paystackConfig = {
    email,
    amount: discountedAmount * 100, // Convert to kobo (multiply by 100)
    publicKey: 'pk_live_2c56a22cea48e53587f18143e5f800f5401f1a7e',
    onSuccess: handlePaymentSuccess,
    onClose: handlePaymentClose,
    reference: `order_${Date.now()}`, // Unique reference for each order
    metadata: {
      customer_name: name,
      customer_email: email,
      delivery_address: address,
      items: itemsMetadata,
    },
  };

  const handleCheckout = (e) => {
    e.preventDefault();
    if (!name || !email || !address) {
      setErrorMessage('Please fill in all the fields.');
      return;
    }
    setErrorMessage('');
  };

  if (cart.length === 0) {
    return (
      <div className="container mx-auto px-4 py-8 bg-white shadow-lg rounded-lg text-center">
        <h2 className="text-4xl font-bold mb-6 text-gray-800">Your Cart is Empty</h2>
        <Link to="/" className="text-blue-600 hover:underline">
          Go back to Shop
        </Link>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 bg-white shadow-lg rounded-lg">
      <h2 className="text-4xl font-bold mb-6 text-center text-gray-800">Checkout</h2>
      {errorMessage && <p className="text-red-600 text-center">{errorMessage}</p>}
      <form onSubmit={handleCheckout} className="grid gap-6 max-w-md mx-auto bg-gray-50 p-6 rounded-lg shadow-md">
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          required
        />
        <textarea
          placeholder="Delivery Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          rows="4"
          required
        />

        <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
          <Link to="/cart" className="text-blue-600 hover:underline mb-4 sm:mb-0">
            &larr; Back to Cart
          </Link>
        </div>

        <div className="text-center mt-6">
          <h3 className="text-xl font-semibold mb-2 text-gray-800">Total Amount: ₦{totalAmount.toFixed(2)}</h3>
          <h3 className="text-lg font-semibold text-green-600 mb-4">Discounted Amount: ₦{discountedAmount.toFixed(2)}</h3>
          <PaystackButton 
            {...paystackConfig} 
            className={`bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 transition duration-200 shadow-md ${!name || !email || !address ? 'opacity-50 cursor-not-allowed' : ''}`} 
            text="Pay with Paystack" 
            disabled={!name || !email || !address}
          />
        </div>
      </form>
    </div>
  );
};
export default Checkout;

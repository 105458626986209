import React, { useEffect, useState } from 'react';
import Header from './Header';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';

const ProductDetail = () => {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [cart, setCart] = useState([]);
    const navigate = useNavigate();
    const [quantity, setQuantity] = useState(1);

    // Fetch product details from API
    const fetchProduct = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/products/${productId}`);
            setProduct(response.data);
        } catch (error) {
            console.error('Error fetching product details:', error);
        }
    };

    // Load cart from localStorage on initial load
    useEffect(() => {
        const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
        setCart(storedCart);
    }, []);

    // Handle quantity change
    const handleQuantityChange = (event) => {
        const value = event.target.value;
        if (value > 0) {
            setQuantity(Number(value));
        }
    };

    // Add product to cart
    const handleAddToCart = () => {
        const existingItem = cart.find(item => item.id === productId);
        let updatedCart;

        if (existingItem) {
            updatedCart = cart.map(item =>
                item.id === productId ? { ...item, quantity: item.quantity + quantity } : item
            );
        } else {
            updatedCart = [...cart, { ...product, quantity }];
        }

        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        alert("Successfully added to cart!");
    };

    // Navigate to cart page
    const navigateToCart = () => {
        navigate('/cart');
    };

    useEffect(() => {
        fetchProduct();
    }, [productId]);

    if (!product) return <div>Loading...</div>;

    return (
        <div>
            <Header />
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 my-10 relative">
                <div className="bg-white shadow-lg rounded-lg p-8 lg:p-10 flex flex-col lg:flex-row">
                    <div className="flex-shrink-0 w-full lg:w-1/2 mb-6 lg:mb-0">
                        <img
                            src={`${API_BASE_URL}/media/${product.image}`}
                            alt={product.name}
                            className="w-full rounded-lg shadow-lg hover:scale-105 transform transition-transform duration-300"
                        />
                    </div>
                    <div className="flex flex-col w-full lg:w-1/2 lg:pl-10 text-gray-800">
                        <h2 className="text-4xl font-bold text-blue-700 mb-4">{product.name}</h2>
                        <p className="text-3xl font-bold text-gray-900 mb-6">${product.price}</p>

                        {/* Quantity Input */}
                        <div className="flex items-center mb-6">
                            <button
                                onClick={() => setQuantity(quantity - 1)}
                                className="px-4 py-2 bg-gray-200 text-gray-600 rounded-l"
                                disabled={quantity <= 1}
                            >
                                -
                            </button>
                            <input
                                type="number"
                                value={quantity}
                                onChange={handleQuantityChange}
                                className="w-16 text-center border-2 border-gray-300 rounded-md"
                            />
                            <button
                                onClick={() => setQuantity(quantity + 1)}
                                className="px-4 py-2 bg-gray-200 text-gray-600 rounded-r"
                            >
                                +
                            </button>
                        </div>

                        <button
                            onClick={handleAddToCart}
                            className="bg-green-500 hover:bg-green-600 text-white font-semibold text-lg px-8 py-3 rounded-lg shadow-lg mb-6 transition duration-200 transform hover:scale-105"
                        >
                            Add to Cart
                        </button>

                        <p className="text-lg text-gray-600 mb-6">{product.short_description}</p>

                        <div className="mt-6">
                            <h3 className="text-2xl font-semibold text-blue-600 mb-2">Description</h3>
                            <p className="text-gray-700 leading-relaxed mb-6">{product.description}</p>
                        </div>

                        <div className="mb-6">
                            <h3 className="text-2xl font-semibold text-blue-600 mb-2">About</h3>
                            <p className="text-gray-700 leading-relaxed">{product.about}</p>
                        </div>

                        {product.video && (
                            <div className="mt-8">
                                <h3 className="text-2xl font-semibold text-blue-600 mb-2">Product Video</h3>
                                <video width="100%" controls className="rounded-lg shadow-lg">
                                    <source src={`${API_BASE_URL}/media/${product.video}`} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        )}
                    </div>
                </div>

                {/* Cart Icon in the Bottom Right Corner */}
                <div className="fixed bottom-4 right-4 lg:bottom-8 lg:right-8 z-50">
                    <button
                        onClick={navigateToCart}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold text-xl p-4 rounded-full shadow-lg transition duration-200 transform hover:scale-105"
                    >
                        <ShoppingCartIcon className="h-6 w-6" />
                    </button>
                </div>

                {/* Sticky Add to Cart Button */}
                <div className="fixed bottom-4 right-24 lg:bottom-8 lg:right-24 z-50">
                    <button
                        onClick={handleAddToCart}
                        className="bg-green-500 hover:bg-green-600 text-white font-semibold text-lg px-8 py-3 rounded-full shadow-lg transition duration-200 transform hover:scale-105"
                    >
                        Add to Cart
                    </button>
                </div>
            </div>
        </div>
        
    );
};

export default ProductDetail;

import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

// Importing images
import firstImage from '../assets/First.jpg';  // Adjust the path as necessary
import secondImage from '../assets/Second.jpg'; // Adjust the path as necessary
import thirdImage from '../assets/Third.jpg';  // Adjust the path as necessary

const Banner = () => {
  const images = [
    { src: firstImage, alt: 'Shop the Latest Deals' },
    { src: secondImage, alt: 'Exclusive Discounts Just for You' },
    { src: thirdImage, alt: 'Hurry! Limited Time Offer' }
  ];

  return (
    <Carousel className="carousel-fade">
      {images.map((image, index) => (
        <Carousel.Item key={index}>
          <img
            className="d-block w-100 h-96 md:h-[500px] object-cover"
            src={image.src}
            alt={image.alt}
          />
          <Carousel.Caption className="bg-opacity-75 bg-black text-white p-4 rounded-lg">
            <h3 className="text-xl md:text-3xl font-bold">{image.alt}</h3>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Banner;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AdminNav from './AdminNav';
import { API_BASE_URL } from '../config';

const AdminProductPage = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({
    name: '',
    price: '',
    shortDescription: '',
    description: '',
    image: null,
    video: null,
    about: ''
  });
  const [editingProduct, setEditingProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  // Fetch products from the Flask API
  const fetchProducts = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/'); // Redirect to login if no token is found
        return;
      }

      const response = await axios.get(`${API_BASE_URL}/products`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setProducts(response.data);
      setFilteredProducts(response.data); // Initialize filteredProducts with all products
    } catch (error) {
      console.error('Error fetching products:', error);
      if (error.response && error.response.status === 401) {
        navigate('/'); // Redirect to login if token is invalid
      }
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  // Handle form input changes for text fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct({ ...newProduct, [name]: value });
  };

  // Handle file input changes for image and video uploads
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setNewProduct({ ...newProduct, [name]: files[0] });
  };

  // Reset form fields and close modal
  const resetForm = () => {
    setEditingProduct(null);
    setNewProduct({
      name: '',
      price: '',
      shortDescription: '',
      description: '',
      image: null,
      video: null,
      about: ''
    });
    setIsModalOpen(false);
  };

  // Add or Update Product
  const handleSaveProduct = async () => {
    const formData = new FormData();
    Object.entries(newProduct).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      };

      if (editingProduct) {
        await axios.put(`${API_BASE_URL}/products/${editingProduct.id}`, formData, config);
      } else {
        await axios.post(`${API_BASE_URL}/products`, formData, config);
      }
      fetchProducts();
      resetForm();
    } catch (error) {
      console.error('Error saving product:', error);
    }
  };

  // Populate form for editing a product
  const handleEditProduct = (product) => {
    setEditingProduct(product);
    setNewProduct({
      name: product.name,
      price: product.price,
      shortDescription: product.short_description || '',
      description: product.description || '',
      image: null,
      video: null,
      about: product.about || ''
    });
    setIsModalOpen(true);
  };

  // Delete product
  const handleDeleteProduct = async (id) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`${API_BASE_URL}/products/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        fetchProducts();
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    }
  };

  // Filter products based on search query
  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = products.filter(product =>
      product.name.toLowerCase().includes(lowerCaseQuery) ||
      product.short_description.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredProducts(filtered);
  }, [searchQuery, products]);

  return (
    <div>
      <AdminNav />
      <div className="container mx-auto px-4 mt-10">
        <h1 className="text-3xl font-semibold mb-6">Admin - Product Management</h1>

        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded mb-6"
        >
          Add New Product
        </button>

        {/* Search Bar */}
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search products..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border rounded p-2 w-full"
          />
        </div>

        {/* Product Table */}
        <div className="overflow-auto">
          <table className="min-w-full bg-white border">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b">Name</th>
                <th className="px-4 py-2 border-b">Price</th>
                <th className="px-4 py-2 border-b">Description</th>
                <th className="px-4 py-2 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts.map((product) => (
                <tr key={product.id}>
                  <td className="px-4 py-2 border-b">{product.name}</td>
                  <td className="px-4 py-2 border-b">${product.price}</td>
                  <td className="px-4 py-2 border-b">{product.shortDescription}</td>
                  <td className="px-4 py-2 border-b">
                    <button
                      onClick={() => handleEditProduct(product)}
                      className="bg-yellow-500 text-white px-2 py-1 rounded mr-2"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeleteProduct(product.id)}
                      className="bg-red-500 text-white px-2 py-1 rounded"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Modal for Add/Edit Product */}
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="bg-white rounded-lg shadow-md p-6 w-full max-w-md">
              <h3 className="text-2xl mb-4">{editingProduct ? 'Edit Product' : 'Add New Product'}</h3>
              
              <input
                type="text"
                name="name"
                placeholder="Product Name"
                value={newProduct.name}
                onChange={handleInputChange}
                className="border rounded p-2 w-full mb-3"
              />
              <input
                type="text"
                name="price"
                placeholder="Price"
                value={newProduct.price}
                onChange={handleInputChange}
                className="border rounded p-2 w-full mb-3"
              />
              <textarea
                name="shortDescription"
                placeholder="Short Description"
                value={newProduct.shortDescription}
                onChange={handleInputChange}
                className="border rounded p-2 w-full mb-3"
              />
              <textarea
                name="description"
                placeholder="Description"
                value={newProduct.description}
                onChange={handleInputChange}
                className="border rounded p-2 w-full mb-3"
              />
              <input
                type="file"
                name="image"
                onChange={handleFileChange}
                className="border rounded p-2 w-full mb-3"
              />
              <input
                type="file"
                name="video"
                onChange={handleFileChange}
                className="border rounded p-2 w-full mb-3"
              />

              <div className="flex justify-end space-x-4">
                <button
                  onClick={resetForm}
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSaveProduct}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  {editingProduct ? 'Update' : 'Add'}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminProductPage;

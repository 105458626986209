import React from 'react';
import { Link } from 'react-router-dom';

const Success = () => {
  return (
    <div className="container mx-auto px-4 py-8 bg-white shadow-lg rounded-lg text-center">
      <h2 className="text-4xl font-bold mb-6 text-green-600">Order Successful!</h2>
      <p className="text-gray-700 mb-4">Thank you for your purchase! Your order is being processed.</p>
      <Link to="/" className="text-blue-600 hover:underline">
        Continue Shopping
      </Link>
    </div>
  );
};

export default Success;
